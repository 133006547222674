@import '../../styles/lib';

.blog-with-sidebar {
  display: flex;
  padding-top: 2.5rem;

  &__content {
    width: 100%;
    padding-right: 3rem;
  }

  &__sidebar {
    width: 24.4rem;
    &-categories {
      text-align: center;
      padding-top: 17.5rem;
      h3 { 
        font-size: 1.8rem;
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        img {
          margin-right: 1rem;
        }
        margin-bottom: 2rem;
      }
      &-item {
        font-size: 1.6rem;
        font-style: italic;
        color: #707070;
        padding: 1rem 0;
        border-bottom: 1px solid #DBDBDB;
        a {
          text-decoration: none;
        }
        &:last-child {
          border-bottom: none;
        }
        &--active {
          color: #BB9947;
        }
      }
    }
  }
  
  &--in-post {
    .blog-with-sidebar {
      &__sidebar {
        &-categories {
          padding-top: 5rem;
        }
      }
    }
  }
  @media (max-width: $breakpoint-mid-tablet) {
    flex-direction: column;
    &__sidebar {
      width: 100%;
      margin-bottom: 3rem;
      &-categories {
        padding-top: 0 !important;
        h3 {
          font-size: 2.2rem;
        }
        &-item {
          font-size: 1.8rem;
        }
      }
    }
    &__content {
      padding-right: 0;
    }
  }
}