 .card-detector {
  &--all {
    display: flex;
    align-items: center;
    justify-content: flex-start;;
    img {
      margin-left: 0.5rem;
      &:first-child {
          margin: 0;
      }
    }
  }
}