@import '../../styles/lib';

.footer {
  &__faq-bar {
    padding: 1.18rem 0;
    background-color: #f1ebda;
  }
  &__faq-content {
    max-width: 99.5rem;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &--terms {
      margin-right: 1rem;
      color: #000;
    }
  }
  &__faq-hr {
    height: 0.1rem;
    background: #fff;
    margin-top: 1.1rem;
  }
  &__faq {
    display: flex;
    align-items: center;
    color: #000;
    &-text {
      margin-left: 1rem;
      font-size: 1.4rem;
      font-weight: bold;
      color: #000;
    }
  }
  &__logos {
    background-color: #fff;
    text-align: center;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__terms {
    background-color: #fff;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  &__image {
    max-width:100%;
    height:auto;
    padding: 0 1.5rem;
    width: 90%;
    max-width: 60rem;
  }

  @media (max-width: $breakpoint-mid-tablet) {
    &__faq {
      padding-right: 2rem;
      &-content {
        text-align: center;
        width: 100%;
        justify-content: center;
      }
    }
  }

  @media (max-width: 500px) {
    &__image {
      width: 90%;
    }
    &__logos {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
