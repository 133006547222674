@import '../../styles/lib';

.faq {
  &__content {
    display: flex;
    margin: 5.6rem auto 4rem;
    max-width: 120rem;
  }
  .header {
    background-color: #f7f7f7;
    &__content {
      padding: 0 4.2rem;
      background: url(../../assets/images/header-image.png) center -15rem no-repeat;
      padding: 8.2rem 6rem;
      min-height: 36.7rem;
      max-width: 120rem;
      margin: 0 auto;
      background: #000;
      border-bottom-left-radius: 13.1rem;
      border-bottom-right-radius: 13.1rem;
      display: flex;
      align-items: center;
    }
    &__title {
      font-size: 6.4rem;
      font-weight: bold;
      line-height: 7.7rem;
      color: #ffffff;
    }
    &__subheader {
      margin-top: 1.5rem;
      font-size: 1.8rem;
      line-height: 2.6rem;
      color: #ffffff;
      max-width: 59.7rem;
    }
    &__image {
      margin-right: 3.2rem;
    }
    &__input-container {
      display: flex;
      align-items: center;
      margin-top: 2.4rem;
    }
    &__input {
      flex: 1;
      margin-right: 2.4rem;
    }
  }
  &__topics {
    width: 28.7rem;
  }
  &__topics {
    border-radius: 1.4rem;
    margin-right: 1.6rem;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  }
  &__questions {
    flex: 1;
  }
  .topic {
    padding: 2.2rem 1.2rem;
    background-color: #ffffff;
    border-bottom: 0.1rem solid #b4bdcb;
    text-align: left;
    transition: none;
    color: #707070;
    outline: none;
    &:last-child {
      border-bottom-left-radius: 1.4rem;
      border-bottom-right-radius: 1.4rem;
    }
    &--selected {
      background-color: #f1ebda;
      font-weight: bold;
      color: #000000;
    }
    &--title {
      border-top-left-radius: 1.4rem;
      border-top-right-radius: 1.4rem;
      font-size: 1.8rem;
      font-weight: bold;
      color: #000000;
    }
  }
  .question {
    margin-bottom: 1.6rem;
    border-radius: 1.4rem;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    &--selected {
      .question__title {
        background-color: #f1ebda;
      }
    }
    &__title {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 1.6rem;
      font-weight: bold;
      padding: 2.9rem 2.36rem;
      border-top-left-radius: 1.4rem;
      border-top-right-radius: 1.4rem;
      text-align: left;
      color: black;
    }
    &__icon {
      display: block;
      margin-right: 1.24rem;
    }
    &__content {
      padding: 3.2rem 2.4rem;
      font-size: 1.6rem;
    }
    &__ArrowDirection {
      display: block;
      align-items: right;
      margin: auto;
      margin-right: 1.24rem;
    }
  }

  @media (max-width: $breakpoint-mid-tablet) {
    img {
      max-width: 100%;
    }
    .header__content {
      flex-direction: column;
    }
    &__content {
      flex-direction: column;
    }
    &__topics {
      margin-bottom: 2rem;
      margin-right: 0;
      width: 100%;
    }
    .header {
      &__input {
        &-container {
          display: flex;
          flex-direction: column;
          .input {
            width: 100%;
            margin: 0 0 1rem;
          }
        }
      }
    }
  }
}
