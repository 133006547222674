@import '../../styles/lib';

.mis-datos {
  &__group {
    &--dark {
      opacity: 0.49;
    }
    padding-top: 3.2rem;
  }
  &__agregar {
    font-size: 1.4rem;
    font-weight: bold;
    color: #707070;
  }
  
  &__row {
    display: flex;
    margin-bottom: 2.4rem;
    .input__label {
      label {
        display: flex;
        width: 100%;
        justify-content: space-between;
        span {
          color: #707070;
          font-style: italic;
          font-weight: 400;
        }
      }
    }
    &--edit {
      margin-left: -1rem;
      margin-right: -1rem;
      display: flex;
      .mis-datos__column {
        margin: 0 1rem !important;
      }
    }
  }
  &__column {
    flex: 1;
    &:first-child {
      margin-right: 4rem;
    }
  }
  &__submit-container {
    p {
      font-size: 1.4rem;
      padding-right: 4rem;
      position: relative;
      &::before {
        content: "*";
        position: absolute;
        left: -1rem;
      }
    }
    display: flex;
    justify-content: space-between;
  }

  &__submit-disclaimer {
    color: #707070;
    margin-top: -1rem;
    margin-bottom: 1rem;
  }

  @media (max-width: $breakpoint-mid-tablet) {
    &__row {
      flex-direction: column;
      .mis-datos__column:first-child {
        margin-right: 0 !important;
        margin-bottom: 2.4rem !important;
      }
    }
    &__submit-container {
      flex-direction: column;
      p {
        padding: 0 0 2rem 0;
      }
    }
  }
}
