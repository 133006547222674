@import '../../styles/lib';

.dialog {
  max-width: 90rem;
  background: #fff;
  position: relative;
  max-height: 90%;
  overflow-y: auto;
  border-radius: 2.5rem;
  -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  overflow: initial;
  &--scroll {
    overflow: auto;
  }
  @media (max-width: $breakpoint-mid-tablet) {
    overflow: initial;
    max-height: 95%;
  }
}
