.cancelar-solicitud {
  width: 90%;
  max-width: 60rem;
  position: relative;
  padding: 5rem 2rem 2rem;
  &__close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 2rem;
    height: 2rem;
    img {
      width: 100%;
    }
  }
  &__actions {
    margin-top: 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  h2, h3 {
    text-align: center;
    font-weight: normal;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
    padding: 2rem 0;
    color: #707070;
  }
  .select, .input {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    &__control-container {
      max-width: 38rem;
      width: 100%;
    }
  }

  .input {
    margin-top: 2rem;
  }
}