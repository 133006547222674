@import '../../styles/lib';

.tf {
  margin: 4.8rem auto;
  background-color: #fff;
  border-radius: 1.8rem;
  padding: 3.2rem 6.4rem;
  &__intro {
    line-height: 2.6rem;
    font-size: 1.8rem;
    margin-bottom: 4rem;
    text-align: center;
  }
  &__title-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: #b4bdcb 0.1rem solid;
  }
  &__title {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 4.8rem;
  }
  &__columns {
    display: flex;
  }
  &__form {
    flex: 1;
    margin-right: 4.8rem;
  }
  &-form {
    padding-top: 2.22rem;
    &__row {
      display: flex;
      margin-bottom: 2.39rem;
      &--anio-vehiculo {
        min-height: 9.2rem;
      }
    }
    &__column {
      flex: 1;
      // &:first-child {
      //   margin-right: 4rem;
      // }
      // & + .tf-form__column {
      //   margin-left: 2.5rem;
      // }
    }
  }
  &__content {
    transition: 0.5s opacity ease-in-out;
    opacity: 0;
    &--loaded {
      opacity: 1;
    }
    &--compress {
      .tf {
        &__form {
          margin: 0;
        }
        &-form__column {
          &--value {
            padding-left: 2rem;
            .currency-input {
              display: flex;
              align-items: center;
              width: 100%;
              &__label {
                margin-right: 1rem;
              }
            }
          }
        }
        @media (max-width: $breakpoint-mid-tablet) {
          &-form {
            &__row {
              &--vehicle {
                flex-direction: column;
              }
            }
            &__column {
              &--value {
                padding-left: 0;
                .currency-input {
                  align-items: flex-start;
                  flex-direction: column;
                  padding-top: 2rem;
                  &__control-container {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__summary {
    flex: 1;
    padding-left: 2.4rem;
    border-left: 0.1rem solid #b4bdcb;
    margin-top: 2.22rem;
    &--extendida {
      width: 100%;
      margin: 0;
      padding: 0;
      align-items: center;
      justify-content: right;
      display: flex;
      border-top: 0.1rem solid #B4BDCB;
      border-left: 0;
      padding-top: 2rem;
      .summary {
        width: 100%;
        max-width: 40rem;
      }
    }
    .summary {
      &__title {
        color: #bb9947;
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 0.8rem;
      }
      &__group {
        margin-bottom: 4rem;
      }
      &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.4rem;
      }
      &__label {
        font-size: 1.4rem;
        font-weight: bold;
      }
      &__value {
        font-size: 1.6rem;
      }
      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2.59rem 1.6rem;
        border-radius: 0.8rem;
        background-color: #f1ebda;
        &-label {
          font-size: 1.8rem;
          font-weight: bold;
        }
        &-value {
          font-size: 2.4rem;
          font-weight: bold;
        }
      }
      &__disclaimer {
        margin-top: 1rem;
        color: #7c7b7b;
        font-size: 1.2rem;
      }
    }
  }
  &__bottom {
    margin-top: 4.6rem;
    font-size: 1.2rem;
    color: #7c7b7b;
  }
  &__submit-container {
    margin-top: 4.8rem;
    display: flex;
    justify-content: center;
  }

  @media (max-width: $breakpoint-mid-tablet) {
    padding-left: 1rem;
    padding-right: 1rem;
    &__columns {
      flex-direction: column;
    }
    &__form {
      margin-right: 0;
    }
    &__summary {
      border-left: none;
      border-top: 0.1rem solid #b4bdcb;
      padding-top: 2rem;
      padding-left: 0;
    }
    &__title-bar {
      flex-direction: column;
      text-align: center;
      > div {
        width: 100%;
      }
      .moneda {
        justify-content: flex-end;
        margin-bottom: 1rem;
      }
    }
  }
}
