@import '../../styles/lib';

.header {
  background-color: #f7f7f7;
  &__content {
    max-width: 120rem;
    // max-width: 100%;
    margin: 0 auto;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  }
  &__slide {
    padding: 0 4.2rem;
    background-image: url(../../assets/images/header-image.png);
    // padding: 8.2rem 6rem;
    // min-height: 36.7rem;
    height: 0;
    padding-bottom: 35%;
    background-position: 0 0;
    background-size: 100%;
    background-repeat: no-repeat;
    &--1 {
      background-image: url(../../assets/images/header-slide-2.png);
    }
  }
  &__title {
    font-size: 6.4rem;
    font-weight: bold;
    line-height: 7.7rem;
    color: #ffffff;
  }
  &__subheader {
    font-size: 1.8rem;
    line-height: 2.6rem;
    color: #ffffff;
    max-width: 59.7rem;
  }
  .header__dots {
    justify-content: flex-start;
    margin-left: 10rem;
    margin-bottom: 2.5rem;
    button {
      border: none;
      background-color: #919292;
    }
    .react-multi-carousel-dot--active button {
      background-color: #fff;
    }
  }

  @media (max-width: $breakpoint-mid-tablet) {
  }
  @media (max-width: 508px) {
    &__slide {
      // background-size: auto 75rem !important;
      // background-size: auto 100%;
      // min-height: 53.6rem;
      // height: 0;
      // padding-bottom: 50%;
      padding-bottom: 109%;
      &--1 {
        background-image: url(../../assets/images/slide-1-mobile.png);
      }
    }
  }
}
