.new {
  * {
    fill: #000;
  }
  &--white {
    * {
      fill: #fff;
    }
  }
}
