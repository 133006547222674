@import '../../styles/variables';
@import '../../styles/lib';

.homepage-blogs {
  padding: 3rem 2rem;
  background: #E4E4E4;
  &__carousel {
    max-width: 150rem;
    margin: 0 auto;
    padding-bottom: 5rem;
    @media screen and (min-width: 964px) {
      justify-content: center;
    }
  }
  &__see-more {
    display: block;
    text-align: center;
    font-size: 1.6rem;
  }
  &__item {
    height: 40rem;
    margin: 0 1rem;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    display: block;
    text-decoration: none;
    max-width: 30rem;
    width: 100%;
    &-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-content {
      z-index: 2;
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      padding: 2rem 2rem 3rem;
    }
    &-date {
      color: #FFFFFF;
      font-size: 1.6rem;
      display: flex;
      justify-content: right;
      align-items: center;
      img {
        margin-right: 0.5rem;
      }
    }
    &-text {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: flex-start;
      h4 {
        font-size: 1.6rem;
        color: white;
        font-weight: normal;
        @include singleEllipsis();
      }
      h5 {
        font-size: 2.4rem;
        color: white;
        font-weight: 700;
        text-align: left;
        height: 5.5rem;
        @include ellipsis(2);
      }
    }
    &--image {
      width: 100%;
      height: 100%;
      position: absolute;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      transition: all .8s;
      &::before {
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(0,0,0, 0.4);
        content: "";
      }
    }
    &:hover {
      .homepage-blogs__item--image {
        transform: scale(1.2);
      }
    }
  }
  h3 {
    text-align: center;
    font-weight: 400;
    font-size: 3.8rem;
    margin-bottom: 2rem;
  }
}