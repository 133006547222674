@import '../../styles/lib';

.mi-solicitud {
  &__editar-container {
    top: 1rem;
    text-align: right;
    position: absolute;
    right: 2rem;
    @media (max-width: $breakpoint-mid-tablet) {
      top: 13rem;
    }
  }
  &__solicitud {
    position: relative;
    border-radius: 1rem;
    margin-top: 2rem;
    // -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    // -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    // box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    // background: white;
  }
  &__link {
    color: #707070;
    font-size: 1.5rem;
  }
}
