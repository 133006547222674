@import '../../styles/lib';

.sign-in-form {
  max-width: 64.5rem;
  padding: 3.2rem 3.49rem;
  &__close-container {
    display: flex;
    justify-content: flex-end;
    @extend %close-icon;
  }
  &__title {
    font-size: 3.7rem;
    font-weight: bold;
    text-align: center;
  }
  &__text {
    text-align: center;
    font-size: 2rem;
    margin-top: 2.4rem;
  }
  &__form {
    width: 46%;
    max-width: 26.5rem;
    margin: 0 auto;
    padding-top: 2.4rem;
    text-align: center;
  }
  &__input-container {
    margin-bottom: 1.6rem;
  }
  &__input {
    // padding: 1.14rem 1.62rem;
    // font-size: 1.4rem;
    // color: #6f6f6f;
    // border: 0.1rem solid #707070;
    // border-radius: 2rem;
    // width: 100%;
  }
  &__forgot-container {
    align-self: flex-start;
    text-align: center;
    margin-top: 1rem;
  }
  &__forgot {
    font-size: 1.2rem;
    color: #000;
    opacity: 0.5;
    text-decoration: underline;
    font-family: $font-family;
  }
  &__submit {
    width: 100%;
    max-width: 26.5rem;
    margin-top: 3.1rem;
  }
  &__register-container {
    margin-top: 1.2rem;
    text-align: center;
    & + .sign-in-form__register-container {
      margin-top: 2rem;
    }
  }
  &__register {
    color: #000;
    font-size: 1.2rem;
  }
  &__error {
    font-size: 1.2rem;
    color: #ff3333;
    text-align: center;
  }
  @media (max-width: $breakpoint-mid-tablet) {
    &__form {
      width: 100%;
    }
    &__submit {
      width: 100%;
    }
  }
}
