@import '../../styles/variables';
@import '../../styles/lib';

.blog-category {
  &__head {
    h1 {
      color: $gold;
      font-size: 4.4rem;
    }
    p {
      font-size: 1.6rem;
      line-height: 2.5rem;
      font-weight: 300;
      margin: 1rem 0;
    }
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    border-bottom: 1px solid $gold;
  }
  &__content {
    @include grid('&-posts--grid') {
      grid-auto-flow: row;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(1, 1fr);
    };

    &-posts {
      margin: 2rem -1rem;
    }
    @media (max-width: $breakpoint-mid-tablet) {
      &-posts {
        margin: 2rem 0rem;
      }
    }

    h2 {
      font-size: 3.4rem;
    }
    h3 {
      font-size: 1.6rem;
      line-height: 2.5rem;
      font-weight: 300;
      margin: 1rem 0;
      p {
        margin-bottom: 2rem;
      }
    }
  }

  &__item {
    margin: 0 1rem 2rem;
    .homepage-blogs__item {
      margin: 0;
    }
    &--desc {
      > p {
        margin: 1rem 0;
        font-size: 1.6rem;
        font-weight: 300;
        min-height: 8rem;
      }

      > a {
        display: block;
        text-align: right;
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 1rem;
        @media (max-width: $breakpoint-mid-tablet) {
          font-size: 2rem;
        }
      }
      @media (max-width: $breakpoint-mid-tablet) {
        > p {
          min-height: initial;
        }
        > a {
          font-size: 2rem;
        }
      }
      .more-link {
        display: none;
      }
    }
  }
}