.testimonial {
  padding: 5rem;
  max-width: 145rem;
  margin: 0 auto;
  width: 100%;
  &-carousel {
    padding-bottom: 3rem;
  }
  &-item {
    border: 1px solid #bb9947;
    margin: 1rem;
    border-radius: 1rem;
    padding: 3rem;
    min-height: 21rem;
    span {
      display: block;
      text-align: center;
    }
    &__content {
      font-style: italic;
      color: #7C7B7B;
      font-size: 1.8rem;
      position: relative;
      padding: 2rem 0;
      &::before,
      &::after {
        content: " ";
        position: absolute;
        width: 3.6rem;
        height: 3.6rem;
        background: url(../../assets/images/quote.svg);
      }
      &::before {
        left: -1.5rem;
        top: -1.5rem;
        transform: rotate(180deg);
      }
      &::after {
        right: -1.5rem;
        bottom: -1.5rem;
      }
    }
    &__author {
      margin-top: 1.5rem;
      font-weight: bold;
      font-size: 2rem;
    }
  }
  &-dot {
    width: 1.9rem;
    height: 1.9rem;
    border-radius: 2rem;
    border: 1px solid #bb9947;
    background: white;
    margin: 0 0.3rem;
    &--active {
      background: #BB9947;
    }
  }
  h3 {
    text-align: center;
    font-weight: 400;
    font-size: 3.8rem;
    margin-bottom: 2rem;
  }
}