@import '../../styles/variables';
@import '../../styles/lib';

.blog-header {
  background: url(../../assets/images/blogHeader.svg) no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
  display: flex;
  padding: 10rem 7rem 7rem;
  flex-direction: column;
  justify-content: center;

  h2 {
    color: white;
    font-size: 2.2rem;
  }
  form {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    position: relative;
    input[type="text"]{
      background: white;
      padding: 1rem;
      border-radius: 2.5rem;
      width: 25rem;
      padding-right: 3.5rem;
    }
    input[type="submit"] {
      background: url(../../assets/images/lookup.svg) no-repeat;
      width: 3.5rem;
      height: 3.5rem;
      background-size: auto;
      position: absolute;
      left: 21.5rem;
      background-position: center;
    }
  }

  @media (max-width: $breakpoint-mid-tablet) {
    margin: 0 -1rem;
    padding: 15rem 6rem 5rem;
    text-align: left;
    height: auto;
    form {
      width: 100%;
      input[type="text"] {
        width: 100%;
        font-size: 1.8rem;
        margin-top: 1rem;
      }
      input[type="submit"] {
        left: initial;
        right: 0;
      }
    }
   }
}