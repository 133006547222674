@import '../../styles/lib';

.pm {
  padding: 5.6rem 4.4rem 5.1rem;
  text-align: center;
  &__title {
    font-size: 3.2rem;
    font-weight: bold;
    max-width: 47rem;
    margin: 0 auto 2.6rem;
  }
  &__content {
    margin-top: 3.1rem;
  }
  &__text {
    font-size: 2rem;
    margin-top: 3rem;
  }

  @media (max-width: $breakpoint-mid-tablet) {
    padding: 1rem;
    &__loading {
      width: 50%;
      margin: 0 auto;
    }
    &__image {
      max-width: 100%;
    }
  }
}
