@import '../../styles/lib';

.user-menu {
  position: relative;
  z-index: 1;
  &__toggle {
    display: flex;
    align-items: center;
  }
  &__button {
    display: flex;
    align-items: center;
  }
  &__label {
    font-size: 1.2rem;
    font-weight: bold;
  }
  &__blog {
    margin-right: 2rem;
    a {
      text-decoration: none;
    }
  }
  &__img {
    display: block;
  }
  &__dropdown {
    position: absolute;
    right: 0;
    top: 100%;
  }
  &__form {
    width: 28.7rem;
    padding: 2.5rem 2.6rem;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.18);
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.18);
    background-color: #ffffff;
    border-bottom-left-radius: 2.5rem;
    border-bottom-right-radius: 2.5rem;
  }
  &__title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2.58rem;
  }
  &__input {
    margin-bottom: 1.6rem;
  }
  &__forgot-container {
    align-self: flex-start;
  }
  &__forgot {
    font-size: 1.2rem;
    color: #000;
    opacity: 0.5;
  }
  &__submit {
    width: 100%;
    margin-top: 3.1rem;
  }
  &__register-container {
    margin-top: 1.2rem;
    text-align: center;
  }
  &__o {
    margin-bottom: 1rem;
  }
  &__register {
    color: #000;
    font-size: 1.2rem;
    text-decoration: underline;
  }
  &__error {
    color: #ff3333;
    margin: 1rem 0;
  }

  @media (max-width: $breakpoint-mid-tablet) {
    width: 100%;
    &__dropdown {
      bottom: 0rem;
      left: 0;
      width: 100%;
    }
    &__toggle {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__blog {
      width: 50%;
      text-align: right;
    }
  }
}
