@import '../../styles/lib';

.response-sent {
  padding: 5.6rem 4.4rem 5.1rem;
  text-align: center;
  position: relative;
  &__title {
    font-size: 3.2rem;
    font-weight: bold;
    max-width: 47rem;
    margin: 0 auto 2.6rem;
  }
  &__content {
    margin-top: 3.1rem;
  }
  &__text {
    font-size: 2rem;
    margin-top: 3rem;
  }
  &__close-container {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 3rem;
    right: 3rem;
    @extend %close-icon;
  }
  &__buttons {
    margin-top: 2.4rem;
    display: flex;
    justify-content: center;
    & > :not(:last-child) {
      margin-right: 5.8rem;
    }
  }
  &__image {
    max-width: 100%;
  }
  @media (max-width: 768px) {
    &__image {
      width: 70%;
      margin: 0 auto;
    }
  }
}
