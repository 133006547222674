@import '../../styles/lib';

.dashboard {
  &__bar {
    background-color: #f1ebda;
    padding: 0.8rem 8.3rem;
  }
  &__badge {
    display: flex;
    align-items: center;
  }
  &__name {
    font-weight: bold;
    font-size: 2rem;
  }
  &__title {
    font-size: 2.6rem;
    font-weight: bold;
    max-width: 88.4rem;
    padding-bottom: 2.5rem;
    margin: 3.4rem auto 0;
    text-align: center;
  }
  &__hr {
    border-top: #dedede 0.1rem solid;
    max-width: 88.4rem;
    margin: 0 auto;
  }
  &__options {
    margin: 5.6rem auto 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    max-width: 88.4rem;
    padding-bottom: 3.45rem;
    .option {
      background-color: #000000;
      border-radius: 0.8rem;
      flex: 1;
      text-align: center;
      padding-bottom: 0.7rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:not(:last-child) {
        margin-right: 5rem;
      }
      &__icon {
        margin-top: -3.2rem;
      }
      &__text {
        color: #bb9947;
        font-size: 1.8rem;
        font-weight: bold;
        margin-top: 1.5rem;
      }
      a {
        text-decoration: none;
      }
    }
  }
  &__operations {
    max-width: 99.3rem;
    margin: 0 auto;
  }
  &__operation {
    margin-bottom: 2.9rem;
  }
  @media (max-width: $breakpoint-mid-tablet) {
    &__bar {
      display: flex;
      justify-content: flex-end;
      padding-right: 2rem;
      padding-left: 2rem;
      height: 6.4rem;
    }
    &__badge {
      .user-badge {
        display: none;
      }
    }
    &__options {
      flex-direction: column;
      width: 100%;
      .option {
        margin: 2rem auto;
        width: 80%;
        min-height: 10.7rem;
        &:not(:last-child) {
          margin: 2rem auto;
          width: 80%;
        }
        &__text {
          max-width: 13rem;
        }
      }
    }
  }
}
