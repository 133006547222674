@import '../../styles/lib';

.tabs {
  .tab {
    background-color: #f1ebda;
    padding: 1.3rem 1.5rem;
    border-radius: 1.3rem;
    border: 0.1rem solid #bb9947;
    font-size: 1.2rem;
    position: relative;
    &:not(:last-child) {
      margin-right: 1.2rem;
    }
    &__label {
      font-weight: bold;
      font-size: 1.6rem;
    }
    &--active {
      &:after {
        content: '';
        position: absolute;
        background: url(../../assets/images/operacion_arrow.svg) 0 0 no-repeat;
        width: 1.7rem;
        height: 0.8rem;
        top: 100%;
        left: 50%;
        margin-left: -0.85rem;
      }
    }
  }
  .panel {
    border-radius: 1.3rem;
    background-color: #f1ebda;
    padding: 1.1rem 0 0;
    margin-top: 2.2rem;
    border: #bb9947 0.1rem solid;
    &__title {
      font-size: 1.6rem;
      font-weight: bold;
      border-bottom: #bb9947 solid 0.1rem;
      padding-bottom: 0.7rem;
      margin: 0 2.4rem;
      &--plate {
        margin-top: 1rem;
      }
    }
    &--open {
      .panel__toggle {
        transform: none;
        margin-top: 0;
      }
    }
    &__toggle {
      margin-left: 0.5rem;
      transform: rotate(180deg);
      margin-top: 0.5rem;
      transition: all 0.5s ease-in-out;
      &--plate {
        img {
          transition: all 0.5s ease-in-out;
        }
        &--active {
          img {
            transform: rotate(180deg);
          }
        }
      }
    }
    &__detail {
      display: flex;
      border-bottom: #bb9947 solid 0.1rem;
      padding-bottom: 1.85rem;
      margin: 1.8rem 2.4rem 0;
      &-col {
        min-width: 3.1rem;
        &:not(:last-child) {
          margin-right: 3rem;
        }
      }
      &-title {
        font-size: 1.8rem;
        font-weight: bold;
        display: flex;
        align-items: center;
      }
      &-value {
        font-size: 1.6rem;
        font-weight: bold;
      }
      &-label {
        font-size: 1.2rem;
        margin-top: 0.7rem;
      }
    }
    &__button {
      width: 14.8rem;
    }
    &__valid-until,
    &__due-date {
      margin-top: 0.4rem;
      font-size: 1.2rem;
    }
    &__payments {
      display: flex;
      align-items: center;
      padding: 2.05rem 2.4rem 1.7rem;
    }
    &__payment-type {
      font-size: 1.4rem;
      font-weight: bold;
      margin-right: 1.8rem;
    }
    &__button {
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
    &__additional {
      background-color: #fff;
      border-bottom-left-radius: 1.3rem;
      border-bottom-right-radius: 1.3rem;
      display: flex;
      align-items: center;
      padding: 3rem 1.8rem 2.89rem;
      &-text {
        font-size: 1.4rem;
        font-weight: bold;
        margin-right: 1.8rem;
      }
      &-check {
        margin-right: 1.4rem;
        display: flex;
        align-items: center;
        &-text {
          margin-left: 0.7rem;
        }
      }
      &-button {
        &--requested {
          color: #bb9947;
          font-size: 1.6rem;
          font-weight: bold;
          min-height: 4rem;
          height: 4rem;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  @media (max-width: $breakpoint-mid-tablet) {
    &__tabs {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      padding-bottom: 1rem;
    }
    .panel {
      flex-wrap: wrap;
      &__detail {
        flex-wrap: wrap;
        &-col {
          min-width: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          &:not(:last-child) {
            margin-right: 0;
          }
          &:first-child {
            min-width: 100%;
            display: block;
            margin-bottom: 3rem;
          }
        }
      }
      &__due-date {
        display: flex;
      }
      &__payments {
        flex-direction: column;
      }
      &__payment-type {
        margin-right: 0;
        margin-bottom: 2rem;
      }
      &__button {
        margin-bottom: 2rem;
        &:not(:last-child) {
          margin-right: 0;
        }
      }
      &__additional {
        flex-direction: column;
        align-items: flex-start;
        &-text {
          margin-right: 0;
          margin-bottom: 1rem;
        }
        &-check {
          margin-right: 0;
          margin-bottom: 1rem;
        }
        &-button {
          margin-top: 2rem;
          align-self: flex-end;
        }
      }
    }
  }
}
