.empty-content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    &__inner {
        padding: 2rem;
        img {
            width: 6rem;
        }
        h2 {
            margin-top: 2rem;
        }
    }
}