@import '../../styles/lib';


.completar-registro {
  max-width: 63rem;
  padding: 3.2rem 3.49rem;
  width: 100vw;
  &__close {
    position: absolute;
    right: 1rem;
    top: 2rem;
    @extend %close-icon;
  }
  &__open-login {
    margin: 2rem auto 0;
    display: block;
    text-decoration: underline;
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__title {
    font-size: 3.7rem;
    font-weight: bold;
    text-align: center;
  }
  &__text {
    text-align: center;
    font-size: 2rem;
    margin-top: 2.4rem;
    white-space: pre-wrap;
    &--footer {
      &::before {
        border-top: 1px solid #BB9947;
        padding-top: 1rem;
        content: " ";
        width: 100%;
        height: 1px;
        /* position: absolute; */
        left: 0;
        display: block;
        max-width: 40rem;
        margin: 0 auto;
      }
      a {
        color: #BB9947;
        font-weight: bold;
      }
    }
  }
  &__name {
    color: #6f6f6f;
    font-size: 2.8rem;
    text-align: center;
    margin-top: 3.4rem;
  }
  &__id {
    color: #6f6f6f;
    font-size: 2.2rem;
    text-align: center;
    margin-top: 1.2rem;
  }
  &__create-title {
    font-size: 1.4rem;
    color: #6f6f6f;
    text-align: center;
    margin-top: 2.38rem;
  }
  &__form {
    width: 46%;
    max-width: 26.5rem;
    margin: 0 auto;
    padding-top: 1.35rem;
  }
  &__input-container {
    margin-bottom: 1.6rem;
    .input__control-container {
      border: none;
      input {
        padding: 0;
      }
    }
    select {
      border-radius: 2rem;
      padding: 1.14rem 1.62rem !important;
      border: 0.1rem solid #707070;
      font-size: 1.4rem;
      &.select__control--error {
        border-width: 0.2rem;
        border:0.1rem solid #bb3030;
      }
    }
  }
  &__input, &__input-new input {
    padding: 1.14rem 1.62rem !important;
    font-size: 1.4rem;
    color: #6f6f6f;
    border: 0.1rem solid #707070;
    border-radius: 2rem;
    width: 100%;
  }
  &__input-new {
    .input__control {
      &-container--error {
        .input__control {
          border-width: 0.2rem;
          border:0.1rem solid #bb3030;
        }
      }
    }
  }
  
  &__submit {
    width: 26.5rem;
    margin-top: 3.1rem;
  }
  @media (max-width: $breakpoint-mid-tablet) {
    max-width: 100%;
    &__form {
      width: 100%;
    }
  }
}
