@import '../../styles/lib';

.mis-operaciones {
  &__text {
    font-size: 1.4rem;
    margin-top: 2.4rem;
  }
  &__tabs {
    margin-top: 1.75rem;
  }
}
