.button {
  padding: 0 3.5rem;
  border-radius: 2rem;
  color: #fff;
  background-color: #bb9947;
  font-size: 1.4rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  height: 4rem;
  &--black {
    background-color: #000;
  }
  &--gray {
    background-color: #707070;
  }
  &__text {
    flex: 1;
  }
  &__prefix {
    margin-right: 1.15rem;
  }
  &__suffix {
    margin-left: 1.15rem;
  }
  &--sm {
    padding: 0 1rem;
  }
  &--disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  &--fullwidth {
    width: 100%;
  }
}
