@import '../../styles/lib';

.requirements {
  background-color: #fff;
  border-radius: 1rem;
  padding: 3.2rem 6.4rem;
  &--profile {
    border-radius: 2.5rem;
    padding: 2.8rem 2.5rem;
  }

  &__forms {
    &--disclaimer {
      font-size: 1.2rem;
      margin: 2rem 0 0;
    }
  }

  &__title {
    border-bottom: #b4bdcb 0.1rem solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-text {
      font-weight: bold;
      font-size: 2.4rem;
      line-height: 4.8rem;
    }
    &-sub {
      font-size: 1.6rem;
      color: #707070;
    }
  }

  &__content {
    padding-top: 3.2rem;
  }
  &__list {
    margin-top: 4rem;
  }
  &__rotate {
    transform: rotate(180deg);
  }
  .field {
    display: flex;
    margin-top: 4rem;
    &__status {
      margin-right: 1.4rem;
    }
    &__content {
      flex: 1;
    }
    &__title {
      font-size: 1.4rem;
      font-weight: bold;
      margin-bottom: 0.2rem;
      display: flex;
      align-items: center;
      > * {
        margin: 0 0.4rem;
      }
    }
    &__optional {
      color: #707070;
      font-size: 1.2rem;
      font-style: italic;
    }
    &__tooltip {
      &-icon {
        position: relative;
        &:hover {
          .field__tooltip-content {
            display: block;
          }
        }
      }
      &-img {
        display: block;
      }
      &-content {
        display: none;
        position: absolute;
        width: 16.4rem;
        font-size: 1.2rem;
        background-color: #f1ebda;
        padding: 0.7rem;
        border-radius: 0.6rem;
        font-weight: normal;
        bottom: 180%;
        left: -0.5rem;
        &:after {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 10px solid #f1ebda;
          position: absolute;
          bottom: -1rem;
        }
      }
    }
    &__requirements {
      font-size: 1.2rem;
    }
    &__input-container {
      display: flex;
      align-items: center;
      margin-top: 0.8rem;
      &--camera {
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: baseline;
        .field__input {
          display: flex;
          width: 100%;
        }
        .field__camera {
          margin-left: 1rem;
        }
        .field__actions {
          margin-top: 1rem;
        }
      }
    }
    &__input {
      margin-right: 1.6rem;
      flex: 1;
      max-width: 46.8rem;
    }
    &__delete {
      display: block;
    }
    &__result {
      font-size: 1.2rem;
      font-weight: bold;
      margin-top: 0.3rem;
    }
    &--approved {
      .field__result {
        color: #4d8400;
      }
    }
    &--rejected {
      .requirements-input {
        border-color: #bc1717;
      }
      .field__result {
        color: #bc1717;
      }
    }
    &__upload {
      background-color: #f1ebda;
      border-radius: 2rem;
      font-size: 1.4rem;
      font-weight: bold;
      color: #000;
      padding: 0.8rem 1.7rem;
    }
    &__download-link {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      font-weight: bold;
      color: #000;
      margin-top: 1rem;
    }
    &__download-icon {
      margin-right: 0.44rem;
    }
    &__actions {
      display: flex;
      > * {
        margin-right: 1.6rem;
      }
      &-buttons {
        display: flex;
        align-items: center;
        > * {
          padding: 0 0.75rem;
          height: 2rem;
          display: flex;
          align-items: center;
        }
        button {
          border-left: 1px solid #939393;
        }
      }
    }
  }
  &-input {
    font-size: 1.4rem;
    padding: 0.7rem;
    border: 0.1rem solid #c6cad9;
    border-radius: 0.8rem;
    min-height: 3.3rem;
    width: 100%;
    &--disabled {
      background-color: #efefef;
    }
  }
  &__legend {
    margin-top: 3.5rem;
    font-size: 1.4rem;
    font-weight: bold;
  }
  &__footer-link {
    color: #bb9947;
    display: inline-flex;
    align-items: center;
  }
  &__footer {
    &-text {
      font-size: 1.4rem;
      margin-top: 0.8rem;
    }
  }
  &__whatsapp {
    margin-left: 0.2rem;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 2.4rem;
    &-button {
      width: 24.1rem;
      position: relative;
      .button {
        &__prefix,
        &__suffix {
          position: absolute;
        }
        &__text {
          text-align: center;
          display: flex;
          width: 100%;
          justify-content: center;
        }
        &__prefix {
          left: 2rem;
        }

        &__suffix {
          right: 2rem;
        }
      }
    }
    &-right {
      display: flex;
      > * {
        margin-left: 0.6rem;
      }
    }
  }

  @media (max-width: $breakpoint-mid-tablet) {
    padding: 3.2rem 2.4rem;
    &__solicitar-content {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    
    &__title {
      flex-direction: column;
      padding-bottom: 2rem;
      &-text {
        width: 100%;
      }
      &-sub {
        display: block;
      }
    }
    &-timeline__timeline {
      .requirements-timeline {
        &__step {
          width: 10rem;
        }
        &__separator {
          margin-left: -3.1rem;
          margin-right: -3.5rem;
        }
      }
    }
    &__buttons {
      flex-direction: column;
      align-items: center;
      &-right {
        margin-top: 2rem;
        flex-direction: column;
        > * {
          margin-left: 0;
        }
      }
      &-button {
        margin-top: 1rem;
      }
    }
    .field {
      &__actions {
        flex-direction: column;
        > * {
          margin-right: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}

.form {
  &__subtitle {
    margin-top:20px;
    border-top: #DEDEDE 0.3rem solid;
    border-bottom: #DEDEDE 0.3rem solid;
    display: fixed;
    align-items: center;
    justify-content: space-between;
    padding:10px;
    &-text {
      font-weight: bold;
      font-size: 2.4rem;
      line-height: 4.8rem;
    }
  }
  &__title {
    width:100%;
    font-weight: bold;
    font-size: 1.3rem;
    align-items: center;
    text-align:center;
    box-sizing: border-box;
    &-text {
      color: #D6C18F;
      
    }
    
  }

  &-section {
    &__disclaimer {
      color: #707070;
      list-style-position: inside;
    }
    &__row {
      position: relative;
    }
  }
  &-plus {
    right: 0;
    padding: 1rem;
    border-radius: 10rem;
    box-shadow: 0rem 0rem 3rem -1rem;
    background: white;
    height: 3.5rem;
    width: 3.5rem;
    margin-bottom: 1.9rem;
    margin-right: 0.5rem;
  }
  @media (max-width: $breakpoint-mid-tablet) {
    &-section {
      &__disclaimer {
        font-size: 1.2rem;
      }
    }
  }
}

.form-container {
  margin-top: 1.9rem;
}
