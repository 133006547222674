@import '../../styles/lib';

.cotizacion {
  margin: 4.8rem auto;
  background-color: #fff;
  border-radius: 1rem;
  padding: 3.2rem 6.4rem;

  &__title {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 4.8rem;
    border-bottom: #b4bdcb 0.1rem solid;
  }
  
  &__content {
    padding-top: 3.2rem;
  }
  
  &__group {
    padding: 0 7.3rem 0;
  }
  
  &__row {
    display: flex;
    margin-bottom: 2.4rem;
    @media (max-width: $breakpoint-mid-tablet) {
      &--mobile {
        flex-direction: column;
        margin: 0;
        .cotizacion__column {
          width: 100%;
          margin: 0 0 2.4rem 0 !important;
        }
      }
    }
  }
  
  &__column {
    flex: 1;
    &:first-child {
      margin-right: 4rem;
    }
  }
  
  &__submit-container {
    margin-top: 4.8rem;
    display: flex;
    justify-content: space-between;
    padding: 0 7.3rem 0;

    &-left {
      display: flex;
      align-items: center;
      &-link {
        margin-left: 1rem;
        color: #000;
        font-weight: bold;
        font-size: 1.4rem;
        text-decoration: underline;
      }
    }
    &-right {
      display: flex;
    }

    @media (max-width: $breakpoint-mid-tablet) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 0;
      margin: 0;
      &-right {
        padding-top: 2rem;
      }
    }
  }
  
  &__legal {
    padding-top: 4.8rem;
    border-top: 0.05rem solid #b4bdcb;
    margin-top: 4.8rem;
  }
  
  &__subtitle {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2.4rem;
  }

  @media (max-width: $breakpoint-mid-tablet) {
    padding-left: 1rem;
    padding-right: 1rem;
    &__group {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
