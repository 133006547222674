@import '../../styles/lib';


button, a, select {
  color: initial;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.desktop {
  display: inherit !important;
}

.mobile {
  display: none !important;
}

@media (max-width: $breakpoint-mid-tablet) {
  .desktop {
    display: none !important;
  }
  
  .mobile {
    display: inherit !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  flex: 1;
  display: flex;
  flex-direction: column;
  &__main {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #f7f7f7;
  }
  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-image: url(../../assets/images/bg_art.png);
    background-position: -200px 200px;
    background-repeat: repeat;
  }
}

.gold {
  color: #bb9947;
}

body > iframe:not([id]) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 999999;
  background: white;
  box-sizing: content-box;
}