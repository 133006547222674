@import '../../styles/variables';
@import '../../styles/lib';

.blog-post {
  &__content {
    &-meta {
      display: flex;
      font-size: 1.6rem;
      &--categories {
        margin-left: 3rem;
      }
      &--date, &--categories {
        display: flex;
        align-items: center;
        color: #7C7B7B;
        a {
          text-decoration: none;
          color: #7C7B7B;
        }
        img {
          margin-right: 0.5rem;
        }
      }
    }
    &-flow {
      h1 {
        color: $gold !important;
        font-size: 2.8rem !important;
        margin: 2.5rem 0 !important;
        font-weight: bold !important;
      }
      &-renderer {
        line-height: 2.8rem !important;
        font-size: 1.6rem !important;
        p {
          margin-bottom: 1rem !important;
        }
      }
    }
    &-steps {
      margin: 3rem 0;
      padding-top: 2rem;
      border-top: 1px solid $gold;
      display: flex;
      justify-content: space-between;
      a {
        &::before, &::after {
          content: "";
          width: 1.5rem;
          height: 1.5rem;
          display: inline-block;
          background: url(../../assets/images/arrow_gold.svg);
          align-items: center;
          margin-right: 0.5rem;
        }
        &::after {
          transform: rotate(180deg);
          margin-left: 0.5rem;
        }
        text-decoration: none;
        font-size: 1.6rem;
        color: #333333;
      }
      &-back {
        a::after {
          content: none;
        }
      }
      &-forward {
        a::before {
          content: none;
        }
      }
    }
  }
}