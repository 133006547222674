.requirements-timeline {
  &__timeline {
    display: flex;
  }
  &__separator {
    flex: 1;
    height: 0.4rem;
    background-color: rgba(187, 153, 71, 0.2);
    align-self: flex-start;
    margin-top: 1.9rem;
    margin-left: -6.5rem;
    margin-right: -6.5rem;
    &--active {
      background-color: #BB9947;
    }
  }
  &__step {
    width: 17rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    &--active {
      .requirements-timeline__title,
      .requirements-timeline__sub-text {
        color: #bb9947;
      }
    }
  }
  &__label {
    text-align: center;
    margin-top: 0.7rem;
  }
  &__title {
    font-size: 1.6rem;
    font-weight: bold;
  }
  &__sub-text {
    font-size: 1.2rem;
    color: #707070;
    margin-top: 0.3rem;
  }
  @media (max-width: 650px) {
    div:nth-of-type(4) {
      display: none;
    }
    
    div:nth-of-type(3) {
      &::after {
        content: "";
        width: 100%;
        height: 3rem;
      }
    }
    
    &__timeline {
      flex-wrap: wrap;
      
    }
    &__separator {
      width: calc(100% - 200px);
      flex: auto;
    }
  }
}
