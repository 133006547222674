.formalization-icon {
  circle {
    stroke: #707070;
  }
  path {
    fill: #707070;
  }
  &--active {
    circle {
      stroke: #bb9947;
    }
    path {
      fill: #bb9947;
    }
  }
}
