.personal-data-icon {
  circle {
    stroke: #707070;
  }
  #Ellipse_214 {
    fill: #707070;
  }
  &--active {
    circle {
      stroke: #bb9947;
    }
    #Ellipse_214 {
      fill: #bb9947;
    }
  }
}
