.blog-search {
  .empty-content {
    height: 40rem;
  }
  h1 {
    font-size: 3.4rem;
    margin: 2rem 0 4rem;
  }
  &__item {
    position: relative;
    padding-bottom: 4rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #bb9947;
    h3 { 
      font-size: 1.8rem;
    }
    p {
      color: #7C7B7B;
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 2rem;
      margin: 1rem 0;
    }
    a {
      font-weight: bold;
      font-size: 1.6rem;
      text-decoration: underline;
      float: right;
    }
  }
  .more-link {
    display: none;
  }
}