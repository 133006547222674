@import '../../styles/lib';

.profile {
  flex: 1;
  display: flex;
  flex-direction: column;
  &__bar {
    background-color: #f1ebda;
    padding: 0.8rem 8.3rem;
  }
  &__badge {
    display: flex;
    align-items: center;
  }
  &__name {
    font-weight: bold;
    font-size: 2rem;
  }
  &__content {
    max-width: 119.6rem;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    flex: 1;
    width: 100%;
  }
  &__menu {
    min-height: 50.1rem;
    background-color: #000000;
    width: 28.7rem;
    padding: 5rem 2.8rem 0;
    margin-right: 1.6rem;
    align-self: stretch;
    max-height: 50rem;
    border-radius: 0 0 3rem 3rem;
    margin-bottom: 3rem;
    .profile__button-text {
      color: white;
    }
  }
  &__button {
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
    text-decoration: none;
    &-icon-container {
      width: 3.5rem;
    }
    &-text {
      color: #000;
      font-size: 2.1rem;
      flex: 1;
      border-radius: 2rem;
      padding: 0.5rem 1.4rem;
    }
    &:last-child {
      .profile__button-text {
        color: #bb9947;
      }
    }
    &--active {
      .profile__button-text {
        background-color: #f1ebda;
        color: black;
      }
    }
  }
  &__viewport {
    flex: 1;
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
  &__mobile-menu-button {
    font-size: 3rem;
    display: none;
  }

  @media (max-width: $breakpoint-mid-tablet) {
    &__bar {
      display: flex;
      justify-content: space-between;
      padding-right: 2rem;
      padding-left: 3.5rem;
      .profile__badge {
        .user-badge {
          display: none;
        }
      }
    }
    &__content {
      flex-direction: column;
    }
    &__menu {
      display: none;
      width: 100%;
      min-height: 0;
      position: absolute;
      z-index: 1;
      &--open {
        display: block;
      }
    }
    &__viewport {
      width: 100%;
    }
    &__mobile-menu-button {
      display: block;
    }
  }
}
