.input {
  border-radius: 0.8rem;
  &__disclaimer {
    height: 3rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.4rem; 
    width: 100%;
    margin-top: -1rem;
    p {
      color: #707070;
      font-size: 1rem;
    }
  }
  &__error {
    p {
      color: red;
    }
  }
  &__label {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0.4rem;
  }
  &__control {
    padding: 1rem;
    font-size: 1.6rem;
    outline: none;
    width: 100%;
  }
  &__control-container {
    border: 0.1rem solid #c6cad9;
    border-radius: 0.8rem;
    display: flex;
    position: relative;
    &:focus-within {
      outline: -webkit-focus-ring-color auto 1px;
    }
    &--has-pre {
      .input__control {
        padding-left: 1rem;
      }
    }
    &--circular {
      border-radius: 2.8rem;
      overflow: hidden;
    }
    &--error {
      border-width: 0.2rem;
      border-color: #bb3030;
    }
  }
  &__control-eye {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-right: 0.5rem;
    &-button {
      background: url('../../assets/images/Icon-eye.png') no-repeat center;
      width: 2rem;
      height: 2rem;
      &--active {
        background: url('../../assets/images/Icon\ awesome-eye-slash.png') no-repeat center;
      }
    }
  }
  &__pre {
    font-size: 1.8rem;
    padding: 1rem 0 1rem 0.8rem;
    font-weight: bold;
    font-style: italic;
    display: flex;
    align-items: center;
  }
  &--size2 {
    .input__control {
      font-size: 1.4rem;
      padding: 0.7rem;
    }
  }
  &--disabled {
    .input__control {
      color: #000000;
    }
  }
  &--type2 {
    border-radius: 2rem;
    .input__control-container {
      border: 0.1rem solid #707070;
      border-radius: 2rem;
      outline: none;
    }
    .input__control {
      color: #6f6f6f;
      border-radius: 2rem;
      font-size: 1.4rem;
      padding: 1.14rem 1.62rem;
    }
  }
  &--background {
    background: #fff;
  }
}
