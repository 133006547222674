.top-bar {
  background-color: #fff;
  padding: 1rem 3.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1001;
  .logo {
    margin-right: 2rem;
  }
}
