@import '../../styles/lib';

.status {
  display: flex;
  &__badge {
    display: flex;
    align-items: center;
    border-radius: 0.8rem;
    color: #fff;
    padding: 3.6rem 2rem 3.6rem;
    margin-right: 3.2rem;
    width: 26.3rem;
    min-width: 26.3rem;
    height: 9.4rem;
    min-height: 9.4rem;
    max-height: 9.4rem;
  }
  &__text {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: left;
  }
  &__img {
    width: 3.2rem;
    margin-right: 1rem;
  }
  &__details {
    border-radius: 0.8rem;
    background-color: #F7F7F7;
    padding: 1.4rem 2.4rem;
    width: 100%;
    p {
      font-size: 1.2rem;
      margin-top: 1rem;
      line-height: 1.8rem;
    }
    &--download {
      font-weight: bold;
      p {
        display: flex;
        align-items: center;
      }
      button {
        color: white;
        font-weight: normal;
        margin-left: 1rem;
        background: #bb9947;
        padding: 0.5rem;
        border-radius: 1rem;
        text-decoration: auto;
      }
    }
    &--options {
      margin-top: 1.5rem;
      font-weight: bold;
      p {
        display: flex;
        align-items: center;
      }
      &--button_black {
        margin-right: 2rem;
        color: white; 
        background: #000000;
        padding: 0.9rem;
        border-radius: 3rem;
        text-decoration: auto;
      }
      &--button_gold{
        color: white;
        background: #bb9947;
        padding: 0.9rem;
        border-radius: 3rem;
        text-decoration: auto;
      }
      &--title{
        margin-bottom:10px;
      }
    }
    &--terms {
      a {
        color: black;
        cursor: pointer;
        text-decoration: underline;
      }
      button {
        &.checkbox {
          margin: 0 1rem 0 0;
        }
      }
    }
    &--conditions {
      @media (max-width: $breakpoint-mid-tablet) {
        button {
          width: 13rem;
        }
      }
    }
  }
  &__title {
    font-weight: bold;
    font-size: 2rem;
  }
  &__number {
    font-size: 2.4rem;
    font-weight: bold;
    margin-top: 0.6rem;
  }
  &__brand {
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 0.6rem;
  }
  &__description {
    font-size: 1.4rem;
    margin-top: 0.6rem;
    flex: 1;
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
  &__link {
    color: #bb9947;
  }
  &--approved {
    .status__badge {
      background-color: #48740b;
    }
    .status__number {
      color: #48740b;
    }
  }
  &--more {
    .status__badge {
      background-color: #92AC3D;
    }
    .status__number {
      color: #92AC3D;
    }
  }
  &--red-grey {
    .status__badge {
      background-color: #CE4921;
    }
    .status__number {
      color: #CE4921;
    }
  }
  &--orange-grey {
    .status__badge {
      background-color: #C18109;
    }
    .status__number {
      color: #C18109;
    }
  }
  &--mustard {
    .status__badge {
      background-color: #D8B212;
    }
    .status__number {
      color: #D8B212;
    }
  }
  &--rejected {
    .status__badge {
      background-color: #D8630E;
    }
    .status__number {
      color: #bc1717;
    }
  }
  &--approved {
    .status__badge {
      background-color: #48740B;
    }
    .status__number {
      color: #48740b;
    }
  }
  &--pending {
    .status__badge {
      background-color: #3e42cb;
    }
    .status__number {
      color: #3e42cb;
    }
  }

  @media (max-width: $breakpoint-mid-tablet) {
    width: 100%;
    margin: 4rem 0;    
    flex-direction: column;
    &__badge {
      width: 100%;
      flex: 1;
      margin-right: 0;
    }
    &__details {
      flex: 3;
      margin-top: 2rem;
    }
    &__title {
      span {
        display: block;
      }
    }
    .mi-solicitud__editar-container {
      top: 13rem;
    }
  }
}
