@import '../../styles/lib';

.slider {
  width: calc(100% + 2rem);
  position: relative;
  padding: 0 3rem;
  margin: 0 -1rem;
  @media (max-width: $breakpoint-mid-tablet) {
    margin: 0;
  }
  &__label {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0.4rem;
  }
  &__control {
    margin-top: 2rem;
    .slider__button {
      position: absolute;
      top: 7rem;
      &--left {
        border-top: 0.3rem solid #000;
        border-left: 0.3rem solid #000;
        transform: rotate(-45deg);
        width: 1rem;
        height: 1rem;
        left: 0;
      }
      &--right {
        border-top: 0.3rem solid #000;
        border-right: 0.3rem solid #000;
        transform: rotate(45deg);
        width: 1rem;
        height: 1rem;
        right: 0;
      }
    }
  }
}
