@import '../../styles/lib';

.profile-menu {
  padding: 0.75rem 1.65rem;
  background-color: #000000;
  border-radius: 1rem;
  color: #fff;
  width: 25.2rem;

  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  list-style: none;
  position: relative;
  margin-top: 0.8rem;
  &__link {
    font-size: 1.6rem;
    color: #fff;
    text-decoration: none;
    padding: 1.25rem 1rem;
    text-align: left;
    border-bottom: 0.1rem solid #f1ebda;
    display: block;
  }
  &__close-session {
    margin-top: 2.15rem;
    color: #bb9947;
    margin-bottom: 0.45rem;
    padding: 0 1rem;
    font-size: 1.6rem;
    font-weight: bold;
  }
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 8px solid #000;
    position: absolute;
    top: -0.8rem;
    right: 2.7rem;
  }

  @media (max-width: $breakpoint-mid-tablet) {
    width: 100%;
  }
}
