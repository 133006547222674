.radio-buttons {
  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  &__label {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 1.1rem;
  }
  &--has-error {
    input {
      border: 1px solid #bb3030;
      border-radius: 15px;
    }
  }
  &__option-label {
    font-size: 1.4rem;
  }
  &__control {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;

    background: url(../../assets/images/radio_unchecked.svg) center no-repeat;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.7rem;
    &:checked {
      background: url(../../assets/images/radio_checked.svg) center no-repeat;
    }
  }
  &__option-input {
    border: rgba(0, 0, 0, 0.3) 0.1rem solid;
    padding: 0.4rem;
    border-radius: 0.4rem;
    margin-left: 2.3rem;
    width: 100%;
    max-width: 29.1rem;
  }
  &--black {
    .radio-buttons__control {
      background: url(../../assets/images/radio_unchecked_black.svg) center
        no-repeat;
      &:checked {
        background: url(../../assets/images/radio_checked_black.svg) center
          no-repeat;
      }
    }
  }
}
