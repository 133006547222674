.checkbox {
  width: 1.8rem;
  height: 1.8rem;
  background-color: #fff;
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &--gold {
    border: 0.2rem solid #BB9947;
    box-shadow: 0 0 1.2rem -0.1rem;
    width: 2.5rem;
    height: 2.5rem;
  }
}
