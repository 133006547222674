@import '../../styles/lib';

.operation {
  border-radius: 3rem;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.09);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.09);
  padding-bottom: 2.5rem;
  background: white;
  &__top-bar {
    padding: 2.35rem 5rem 0;
  }
  &__top-content {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  &__label {
    margin-right: 1rem;
    font-size: 1.8rem;
    font-weight: bold;
  }
  &__number {
    color: #bb9947;
    font-size: 1.8rem;
    font-weight: bold;
    margin-right: 1rem;
  }
  &--open {
    .operation__arrow {
      transform: rotate(180deg);
    }
  }
  &__control {
    padding: 0.2rem 1rem;
    display: flex;
    align-items: center;
  }
  hr {
    border-top: #dedede 0.1rem solid;
    margin: 0 auto;
    flex: 1;
    &.operation__hr-plate {
      border-top: #dedede 0.1rem solid;
      margin-left: 1rem;
      flex: 1;
    }
  }
  &__content {
    padding: 0.6rem 5rem 2.8rem;
    margin-top: 0.65rem;
  }
  &__plate {
    &, button {
      color: #707070;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      img {
        transition: 0.5s all ease-in-out;
        margin-left: 0.5rem;
      }
    }
    &--inner {
      margin-bottom: 1rem;
    }
    &--active {
      img {
        transform: rotate(180deg);
      }
    }
  }
  &__details {
    margin-top: 1.9rem;
    display: flex;
  }
  &__box {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 0.8rem;
    background-color: #fff;
    flex: 1;
    display: flex;
    &:not(:last-child) {
      margin-right: 1.6rem;
    }
    &-left {
      border-top-left-radius: 0.8rem;
      border-bottom-left-radius: 0.8rem;
      width: 8.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2.6rem;
      padding-bottom: 2.6rem;
    }
    &-right {
      padding: 1.3rem 4rem 0.9rem;
      text-align: center;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &-label {
      font-size: 1.6rem;
      color: #707070;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &--quote {
      .operation__box-left {
        background-color: #bb9947;
      }
    }
    &--days {
      .operation__box-left {
        background-color: #000;
      }
      .operation__box-right {
        padding-left: 0.97rem;
        padding-right: 0.97rem;
        .operation__box-label {
          text-align: left;
        }
      }
    }
    &--number {
      .operation__box-left {
        background-color: #707070;
      }
      .operation__box-right {
        padding-left: 3.8rem;
        padding-right: 3.8rem;
      }
    }
  }
  &__quote {
    font-size: 4.9rem;
    color: #000000;
  }
  &__days-container {
    display: flex;
  }
  &__days {
    font-size: 4.9rem;
    margin-right: 0.9rem;
  }
  &__quote-number {
    color: #707070;
    font-size: 4.1rem;
  }

  @media (max-width: $breakpoint-mid-tablet) {
    &__details {
      flex-direction: column;
    }
    &__box {
      margin: 2rem auto;
      width: 100%;
      &:not(:last-child) {
        margin: 2rem auto;
        width: 100%;
      }
      &--right {
        .operation__box-right {
          .operation__box-label {
            max-width: 10rem;
          }
        }
      }
    }
    &__days-container {
      justify-content: center;
    }
  }
}
