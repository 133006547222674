@import '../../styles/lib';

.blog {
  &-content {
    max-width: 120rem;
    margin: 0 auto;
    width: 100%;
  }
  @media (max-width: $breakpoint-mid-tablet) { 
    &-content {
      width: 100%;
      padding: 0 1rem;
    }
  }
}