.select {
  &__label {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0.4rem;
  }
  &__control-container {
    display: flex;
    align-items: center;
    &--disabled {
      opacity: 0.7;
      pointer-events: none;
    }
  }
  &__control {
    &--circular {
      border-radius: 2.8rem;  
    }
    padding: 1rem;
    font-size: 1.6rem;
    outline: none;
    width: 100%;
    border: 0.1rem solid #c6cad9;
    border-radius: 0.8rem;

    -webkit-appearance: none;
    appearance: none;
    padding-right: 2.5rem;
    &--error {
      border-width: 0.2rem;
      border-color: #bb3030;
    }
    &--empty {
      color: #707070;
    }
  }
  &__arrow {
    pointer-events: none;
    margin-left: -2rem;
    font-size: 1.8rem;
    color: #010002;
    opacity: 0.6;
  }
}
