.success-dialog {
  max-width: 85.8rem;
  padding: 3.2rem 3.49rem;
  text-align: center;
  &__message {
    font-size: 3.2rem;
    font-weight: bold;
  }
  &__button {
    margin: 4.78rem auto 0;
    width: 17.5rem;
  }
}
