@import '../../styles/lib';

.vehicle-type {
  display: flex;
  align-items: center;
  &__label {
    margin-right: 1.6rem;
    font-weight: bold;
    font-size: 1.8rem;
  }
  &__button {
    border-radius: 1.8rem;
    padding: 0 1.7rem;
    width: 13.6rem;
    height: 3.7rem;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 6px 0px rgba(136, 136, 136, 0.36);
    -webkit-box-shadow: 0px 2px 6px 0px rgba(136, 136, 136, 0.36);
    -moz-box-shadow: 0px 2px 6px 0px rgba(136, 136, 136, 0.36);
    background-color: rgba(187, 153, 71, 0.2);
    &--new {
      margin-right: 2.4rem;
      .vehicle-type__icon {
        margin-top: 0.55rem;
        margin-bottom: 0.55rem;
      }
    }
    &--active {
      background-color: #000;
      color: #fff;
    }
  }
  &__icon {
    margin-right: 1.27rem;
  }

  @media (max-width: $breakpoint-mid-tablet) {
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    &__label {
      width: 100%;
    }
    &__button {
      margin: 1rem 0.5rem;
    }
  }
}
