.moneda {
  display: flex;
  align-items: center;
  &__label {
    margin-right: 0.8rem;
    font-size: 1.8rem;
    font-weight: bold;
  }
  &__btn {
    padding: 0.2rem 1rem;
    font-size: 2rem;
    &--active, 
    &--single {
      background-color: #bb9947;
      color: #fff;
    }
    &--single {
      border-radius: 3rem;
      padding: 0.2rem 1.5rem
    }
  }
  &__dollar {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    border-top: 0.1rem solid #707070;
    border-left: 0.1rem solid #707070;
    border-bottom: 0.1rem solid #707070;
  }
  &__colon {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    border-top: 0.1rem solid #707070;
    border-right: 0.1rem solid #707070;
    border-bottom: 0.1rem solid #707070;
  }
}
