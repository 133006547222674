@import '../../styles/lib';

.benefits {
  &__nocall {
    max-width: 128rem;
    margin: 0 auto;
  }

  &__banner {
    background: url(../../assets/images/banner-beneficios.png) 0 0 no-repeat;
    height: 33.4rem;
    padding: 0 4.2rem 8.1rem;
    display: flex;
    padding-top: 7.03rem;
  }

  &__title {
    font-size: 3.6rem;
    font-weight: bold;
    padding-bottom: 1.95rem;
    max-width: 39rem;
    padding-right: 1.65rem;
    border-right: 0.3rem solid #bb9947;
    color: #fff;
  }

  &__sub-title {
    flex: 1;
    padding-left: 3.05rem;
    font-size: 2.4rem;
    color: #fff;
    margin-top: 2rem;
  }

  &__boxes {
    max-width: 119.6rem;
    margin: 0 auto;
    display: flex;
    margin-top: -10rem;
    justify-content: center;
  }

  &__box {
    border-radius: 1rem;
    flex: 1;
    background: #000;
    padding: 3.2rem 2.9rem 7.2rem;
    color: #bb9947;
    font-size: 2.8rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-text {
      margin-top: 1.5rem;
      max-width: 25.8rem;
      text-align: center;
    }

    &:not(:last-child) {
      margin-right: 1.6rem;
    }
  }

  &__icon-container {
    align-self: flex-start;
  }

  &__helper {
    margin-top: 3.8rem;
    margin-bottom: 6.5rem;
    display: flex;
    align-items: center;

    &-left {
      font-size: 2.5rem;
      font-weight: bold;
      color: #000;
      border: 0.1rem solid #bb9947;
      padding: 4.55rem 13rem 4.55rem 12.2rem;
      margin-left: -10rem;
      max-width: 54.4rem;
      border-radius: 1rem;
      line-height: 3.7rem;
    }

    &-line {
      &:not(:last-child) {
        padding-bottom: 5rem;
        border-bottom: 0.2rem solid #bb9947;
        margin-bottom: 3.65rem;
      }
    }

    &-right {
      margin-bottom: -6.7rem;

      &-content {
        display: flex;
      }

      margin-left: -10rem;

      &-box {
        display: flex;
        flex-direction: column;
        margin-left: 1.6rem;
        background-color: #bb9947;
        padding: 2.6rem 3.2rem 1.4rem;
        border-radius: 1rem;

        &-title {
          font-size: 2.8rem;
          font-weight: bold;
        }

        &-subtitle {
          color: #fff;
          margin-top: 1rem;
          flex: 1;
          font-size: 1.5rem;
          margin-left: 1rem;
        }

        &-list {
          color: #fff;
          flex: 1;
          font-size: 1.4rem;
          list-style-position: inside;
          margin-left: 1rem;
        }
      }

      &-disclaimer {
        font-size: 1.3rem;
        text-align: right;
        margin-top: 1.5rem;
      }

      &-goals {
        font-size: 2.2rem;
        margin-top: 4.1rem;
        text-align: right;
        font-weight: bold;
      }
    }
  }

  &__columns {
    margin-top: 1.85rem;
    display: flex;
    padding: 0 3.1rem;
  }

  &__column {
    font-size: 1.4rem;
    width: 50%;

    &:first-child {
      margin-right: 9.9rem;
    }
  }

  &__carousel {
    margin-top: 4.8rem;
    display: flex;
    justify-content: center;
  }

  @media (max-width: $breakpoint-mid-tablet) {
    width: 100%;

    &__title {
      border-right: 0;
    }

    &__banner {
      flex-direction: column;
      padding-bottom: 10rem;
      padding-top: 1rem;
      height: auto;
    }

    &__boxes {
      margin-top: -5rem;
      flex-direction: column;
    }

    &__box {
      margin-bottom: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    &__helper {
      flex-direction: column;

      &-right-content {
        margin: 0 10px;
      }

      &-car-keys {
        max-width: 100%;
      }

      &-left {
        margin-left: 0;
        margin: 0 10px;
        padding: 45px;
      }

      &-right {
        margin: 2.4rem auto;

        &-content {
          flex-direction: column;
        }

        &-box {
          margin-left: 0;
          margin-top: 2rem;

          &-subtitle {
            margin-bottom: 0;
          }
        }

        &-goals {
          text-align: center;
        }
      }
    }

    &__sub-title {
      padding-left: 0;
      margin-top: 2rem;
    }

    &__columns {
      flex-direction: column;
    }

    &__column {
      width: 100%;
      margin-bottom: 2rem;

      &:first-child {
        margin-right: 0;
      }
    }
  }
}


/* Call action */

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptsg8zYS_SKggPNyCg4QIFqPfE.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptsg8zYS_SKggPNyCg4SYFqPfE.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptsg8zYS_SKggPNyCg4QoFqPfE.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptsg8zYS_SKggPNyCg4Q4FqPfE.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptsg8zYS_SKggPNyCg4TYFq.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptsg8zYS_SKggPNyCg4QIFqPfE.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptsg8zYS_SKggPNyCg4SYFqPfE.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptsg8zYS_SKggPNyCg4QoFqPfE.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptsg8zYS_SKggPNyCg4Q4FqPfE.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptsg8zYS_SKggPNyCg4TYFq.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpbCFPrEHJA.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpbCMPrEHJA.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* vietnamese */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpbCHPrEHJA.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpbCGPrEHJA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpbCIPrE.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.callActionHub {
  width: 100%;
  height: 480px;
  background-image: url(https://prod-agecapital-cr.azurewebsites.net/wp-content/uploads/2023/04/agecapital_callaction.webp);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
  margin-top: 40px;
}

.w-half {
  flex: 40%;
  display: inline-flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-content: center;
  padding: 0 5%;
  font-family: 'Raleway', sans-serif;

  img {
    height: 90px;
    margin: 0 40px;
  }

  h6 {
    font-size: 1.2em;
    line-height: 1.5em;
    font-weight: 800;
    width: 50%;
    margin-right: 50%;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  a {
    color: #000;
    text-decoration: none;
    position: relative;
    border-bottom: solid 2px #BB9947;
    height: 28px;
    width: 50%;
    transition: all 0.4s;
    font-size: 1.7em;

    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-right: solid 2px #BB9947;
      border-bottom: solid 2px #BB9947;
      right: 20px;
      transform: rotate(-45deg);
      transition: all 0.4s;
      opacity: 0.5;
    }

    &:hover {
      &:before {
        right: 0px;
        transition: all 0.4s;
        opacity: 1;
      }

      color: #BB9947;
    }
  }

  &:last-child {
    justify-content: flex-start;
  }
}

@media (max-width: 991px) {
  .callActionHub {
    height: auto;
    width: 100vw;
    background-size: 250% auto;
    background-position: right bottom;
  }

  .w-half {
    flex: calc(100% - 60px);
    justify-content: center;
    padding: 30px;

    img {
      height: 60px;
      margin: 30px;
    }

    h6 {
      width: 100%;
      margin-right: 0;
      text-align: center;
    }

    a {
      margin: 0 auto;
    }

    &:first-child {
      background-color: #d3d3d399;
      ;
      backdrop-filter: blur(3px);
    }
  }
}