@import '../../styles/lib';

.metodo-de-pago {
  &__text {
    margin-top: 1.4rem;
    font-size: 1.4rem;
  }
  &__row {
    display: flex;
    margin-bottom: 3.7rem;
    margin-top: 3.7rem;
    &--initial {
      width: 100%;
      & > div {
        width: 100%;
      }
    }
    &--pago-parcial {
      .radio-buttons__control-container {
        display: flex;
        @media (max-width: $breakpoint-mid-tablet) {
          flex-direction: column;
        }
        > div:first-child {
          margin-right: 3rem;
        }
      }
    }
  }
  &__column {
    &:not(:last-child) {
      margin-right: 2.1rem;
    }
  }

  &__hr {
    border-top: #dedede 0.1rem solid;
  }
  &__forma {
    padding-right: 2.2rem;
    border-right: 0.1rem solid #c6cad9;
  }
  &__paybac {
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: bold;
    text-align: center;
  }
  &__extra {
    padding-left: 2.6rem;
  }
  &__card-details {
    margin-top: 1.9rem;
    display: flex;
  }
  &__card-detector {
    margin-top: 0.67rem;
    height: 2rem;
  }
  &__submit-container {
    display: flex;
    justify-content: var(--var-jc);
    &-left {
      margin-right: 1rem;
      margin-left: 25%;
      display: flex;
      align-items: center;
      &-link {
        margin-left: 1rem;
        color: rgb(0, 0, 0);
        font-weight: bold;
        font-size: 1.4rem;
        text-decoration: underline;
      }
    }
    &-right {
      display: flex;
    }

    @media (max-width: $breakpoint-mid-tablet) {
      flex-direction: column;
      align-items: center;
      &-left {
        margin-right: initial;
        margin-left: initial;
      }
      &-right {
        padding-top: 2.5rem;
      }
    }
  }

  &__acceptTYC {
    padding: 0 1rem;
    border-radius: 2rem;
    border:1px solid #E3031B;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    height: 4rem;

    &-text{
      color: #E3031B;
      font-size: 12px;
      font-weight: 400;
      margin-right: 1rem;
    }
  }

  &__al-dia {
    color: #48740B;
    display: block;
    margin: -3rem 0 1.8rem 0;
    font-weight: 500;
    font-size: 1.2rem;
  }
  
  @media (max-width: $breakpoint-mid-tablet) {
    &__row {
      flex-direction: column;
    }
    &__column {
      margin-bottom: 2rem;
      &:not(:last-child) {
        margin-right: 0;
      }
    }
    &__forma {
      border-right: none;
      border-bottom: 0.1rem solid #c6cad9;
      margin-bottom: 2rem;
    }
    &__extra {
      padding-left: 0;
    }
    &__card-details {
      flex-direction: column;
    }
    &__submit-container {
      justify-content: center;
    }
  }
}


.pay-modal {
  position: relative;
  img.response-sent__image {
    position: absolute;
    top: -12rem;
    width: auto;
    left: 0;
    height: auto;
    right: 0;
    margin: auto;
  }
  .response-sent {
    padding: 7.6rem ​4.4rem 5.1rem;
    &__image {
      margin-top: 0 !important;
    }
  }
}

.nueva-afiliacion {
  .response-sent__text {
    a {
      color: #BB9947;
      font-weight: bold;
    }
  }
}