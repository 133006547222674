@import '../../styles/lib';

.flow {
  max-width: 99.5rem;
  margin: 0 auto;
  width: 80%;
  &__requirements {
    margin: 4.8rem auto;
  }

  @media (max-width: $breakpoint-mid-tablet) {
    width: 100%;
  }
}
