.user-badge {
  width: 3.6rem;
  height: 3.6rem;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  background-color: #000000;
  border-radius: 2.5rem;
  margin: 0.6rem 0.9rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
