@import '../../styles/lib';

.resultado-cotizacion {
  margin: 4.8rem auto;
  background-color: #fff;
  border-radius: 1rem;
  padding: 5rem 4.8rem;

  @media (max-width: $breakpoint-mid-tablet) {
    padding: 5rem 1.8rem;
  }

  &__title {
    font-weight: bold;
    font-size: 2.4rem;
  }
  &__text {
    font-size: 1.4rem;
  }
  &__title-container {
    border-bottom: #b4bdcb 0.1rem solid;
    padding-bottom: 1.25rem;
  }
  &__arrow--left {
    transform: rotate(180deg);
  }
  &__summary {
    display: flex;
    padding-top: 3.25rem;
    flex: 2;
    border-bottom: #b4bdcb solid 0.1rem;
    padding-bottom: 3.2rem;
    &-table {
      min-width: 53.2rem;
      padding: 2.4rem;
      background-color: #f7f7f7;
      border-radius: 0.8rem;
      margin-right: 3.2rem;
      display: flex;
      flex-direction: column;
    }
    &-title {
      font-size: 2.4rem;
      margin-bottom: 0.6rem;
      font-weight: bold;
    }
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
    }
    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.4rem;
    }
    &-label {
      font-weight: bold;
      font-size: 1.6rem;
    }
    &-value {
      font-size: 1.6rem;
    }
    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__quote-label {
    font-size: 1.6rem;
    font-weight: bold;
  }
  &__quote-value {
    font-size: 2.4rem;
    font-weight: bold;
    color: #bb9947;
  }
  &__aside {
    flex: 1;
  }
  &__result-badge {
    margin-bottom: 1.3rem;
  }
  &__letter {
    padding: 1.2rem 1.6rem;
    background-color: #f1ebda;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    &-text {
      font-size: 1.2rem;
      width: 18.4rem;
      margin-right: 1.6rem;
    }
    &-link {
      display: flex;
      align-items: flex-end;
      color: #000;
      font-weight: bold;
      font-size: 1.2rem;
      white-space: nowrap;
    }
    &-icon {
      margin-right: 0.61rem;
    }
  }
  &__adicionales {
    padding-top: 2.4rem;
    padding-bottom: 4rem;
    border-bottom: 0.1rem solid #b4bdcb;
    &-content {
      display: flex;
      margin-top: 2.4rem;
      justify-content: center;
    }
  }
  &__adicional {
    width: 19.4rem;
    margin-right: 4rem;
    border-radius: 1.8rem;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    &-header {
      position: relative;
      width: 100%;
    }
    &-checkbox {
      position: absolute;
      z-index: 2;
      top: 0.8rem;
      right: 1.2rem;
    }
    &-image {
      display: block;
      z-index: 1;
      height: 9.2rem;
      width: 100%;
    }
    &-content {
      padding: 1.2rem 1.5rem 3.5rem;
      color: #fff;
      background-color: #000;
      text-align: center;
      border-bottom-right-radius: 1.8rem;
      border-bottom-left-radius: 1.8rem;
      flex: 1;
      &--gps {
        padding: 1.2rem 1.5rem 6.5rem;
      }
    }
    &-title {
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 0.2rem;
    }
    &-text {
      font-size: 1.2rem;
    }
    &-price {
      position: absolute;
      bottom: 1rem;
      font-size: 1.4rem;
      font-weight: bold;
      width: 100%;
      left: 0;
      color: #bb9947;
      > * {
        text-decoration: underline;
      }
      
      a {
        color: #bb9947;
      }
      .monto-fijo {
        text-decoration: none;
        display: block;
        margin-bottom: 1rem;
      }
    }
    &--gps {
      background-color: #f1ebda;
      .resultado-cotizacion__adicional-content {
        background-color: transparent;
        color: #000;
      }
    }
    &--alquiler {
      .resultado-cotizacion__adicional-content {
        padding-top: 1.1rem;
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 3.2rem;
    &-left {
      display: flex;
      align-items: center;
      &-link {
        margin-left: 1rem;
        color: #000;
        font-weight: bold;
        font-size: 1.4rem;
        text-decoration: underline;
      }
    }
    &-right {
      display: flex;
    }
    &-button {
      margin-left: 1.6rem;
      width: 23rem;
    }
  }
  &__trigger {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.6rem;
  }
  &__button {
    font-size: 1.4rem;
    font-weight: bold;
    text-decoration: underline;
  }
  &__alternativas-content {
    margin-top: 4rem;
    border-top: 0.4rem solid #bb9947;
    padding-top: 3rem;
  }
  &__alternativas {
    &-columns {
      display: flex;
      align-items: flex-end;
    }
    @media (max-width: 665px) {
      &-columns {
        width: 100%;
        flex-direction: column;
        align-items: baseline;
      }
      &-left {
        width: 100%;
        input[type="radio"] {
          width: 1.6rem;
          height: 1.6rem;
          min-width: 1.6rem;
        }
      }
      &-right {
        margin-top: 3rem;
        justify-content: center !important;
        align-items: center;
        width: 100%;
      }
    }
  }
  &__alternativas-left {
    flex: 1;
  }
  &__alternativas-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  &__alternativas-legend {
    margin-top: 3.5rem;
    font-size: 1.4rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    @media (max-width: 665px) {
      flex-direction: column;
      text-align: center;
      a {
        margin-top: 1rem;
      }
    }
  }
  &__alternativas-link {
    margin: 0 0.61rem;
    color: #bb9947;
  }
  &__alquiler {
    background-color: #f7f7f7;
    margin-top: 4rem;
    border-radius: 2rem;
    padding: 4rem 2rem 2rem;
    position: relative;
    > button {
      position: absolute;
      top: 1.5rem;
      right: 2rem;
      img {
        width: 1.5rem;
      }
    }
    &-intro {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    &-carro {
      margin-right: 1rem;
      margin-bottom: 2rem;
      margin-left: 1rem;
    }
    &-disclaimer {
      border-top: 1px solid #c3aa71;
      padding-top: 2rem;
      text-align: center;
      &-link {
        color: #000;
        font-weight: bold;
      }
    }
  }
  @media (max-width: $breakpoint-mid-tablet) {
    &__summary {
      flex-direction: column;
      &-table {
        flex-direction: column;
        min-width: 0;
        margin-right: 0;
        margin-bottom: 2rem;
      }
      &-content {
        flex-direction: column;
      }
    }
    &__adicionales-content {
      flex-direction: column;
      align-items: center;
    }
    &__adicional {
      margin-right: 0;
      margin-bottom: 2rem;
    }
    &__alquiler {
      &-carro {
        margin-right: 0;
      }
    }
    &__footer {
      flex-direction: column;
      &-left {
        margin-bottom: 2rem;
      }
      &-right {
        flex-direction: column;
      }
      &-button {
        margin-bottom: 10px;
        margin-left: 0;
      }
    }
  }
}

.vehicle {
  max-width: 19.4rem;
  border-radius: 1.8rem;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  border: 1px solid #c3aa71;
  overflow: hidden;
  &-header {
    position: relative;
  }
  &-checkbox {
    position: absolute;
    z-index: 2;
    top: 0.8rem;
    right: 1.2rem;
  }
  &-image {
    display: block;
    z-index: 1;
    width: 100%;
  }
  &-content {
    padding: 1.2rem 1.5rem 3.5rem;
    text-align: center;
    border-bottom-right-radius: 1.8rem;
    border-bottom-left-radius: 1.8rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  &-text {
    font-size: 1.2rem;
  }
  &-price {
    font-size: 1.6rem;
    font-weight: bold;
    margin-top: 2rem;
    color: #c3aa71;
  }
  &--alquiler {
    .resultado-cotizacion__adicional-content {
      padding-top: 1.1rem;
    }
  }
  @media (max-width: $breakpoint-mid-tablet) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
