/// List of browser width breakpoints, available thru `screen` mixin
/// @type map
/// @group breakpoints
$breakpoints-map: (
  'small': 360,
  'medium': 600,
  'large': 1020,
  'xl': 1280,
  'giant': 1600,
);

/// Get the value from `$breakpoints-map`, by name.
/// > For media queries, use the `screen` mixin.
/// @group breakpoints
/// @require {variable} $breakpoints-map
/// @example scss - Get the breakpoint for "medium"
///     breakpoint(medium);
///     // 760
/// @param {string} $name - Name of the breakpoint
/// @returns {number} Value of the breakpoint
@function breakpoint($name) {
  @return safe-lookup(
    $breakpoints-map,
    $name,
    0,
    'Breakpoint "#{$name}" does not exist! Please choose from the following: #{map-keys($breakpoints-map)}'
  );
}

$breakpoint-tablet: 768px;
$breakpoint-mid-tablet: 985px;
$breakpoint-tablet-landscape: 1150px;
$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;

%close-icon {
  @media (max-width: $breakpoint-mid-tablet) { 
    right: 2rem !important;
    top: 2rem !important;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@mixin ellipsis($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin singleEllipsis() {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

@mixin grid($class) {
  #{$class} {
    display: grid;
    @content;
  }
  @media screen and (max-width: $breakpoint-mid-tablet) {
    #{$class} {
      display: block;
    }
  }
}