@import '../../styles/variables';
@import '../../styles/lib';

.blog-capsula {
  &__selected {
    display: flex;
    iframe {
      height: 49rem;
      width: 100%;
    }
  }
  &__options {
    margin-top: 3rem;
    h2 {
      font-size: 2.4rem;
      font-weight: bold;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $gold;
    }
    @include grid('&-grid') {
      margin-top: 1.5rem;
      grid-auto-flow: row;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(1, 1fr);
      a {
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,0.4);
          z-index: 1;
        }
        text-decoration: none;
        text-align: center;
        position: relative;
        background-repeat: no-repeat;
        margin: 1rem;
        background-size: cover;
        height: 15rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        > div {
          position: relative;
          z-index: 2;
        }
      }
    };
  }
}