.date-picker {
  border-radius: 0.8rem;
  &__label {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0.4rem;
  }
  &__control {
    padding: 1rem;
    font-size: 1.6rem;
    outline: none;
    width: 100%;
  }
  &__control-container {
    border: 0.1rem solid #c6cad9;
    border-radius: 0.8rem;
    display: flex;
    &:focus-within {
      outline: -webkit-focus-ring-color auto 1px;
    }
    &--has-pre {
      .input__control {
        padding-left: 1rem;
      }
    }
    &--error {
      border-width: 0.2rem;
      border-color: #bb3030;
    }
  }
  &__pre {
    font-size: 1.8rem;
    padding: 1rem 0 1rem 0.8rem;
    font-weight: bold;
    font-style: italic;
    display: flex;
    align-items: center;
  }
  &--size2 {
    .input__control {
      font-size: 1.4rem;
      padding: 0.7rem;
    }
  }
  &--disabled {
    .input__control {
      color: #000000;
    }
  }
  &--type2 {
    border-radius: 2rem;
    .input__control-container {
      border: 0.1rem solid #707070;
      border-radius: 2rem;
      outline: none;
    }
    .input__control {
      color: #6f6f6f;
      border-radius: 2rem;
      font-size: 1.4rem;
      padding: 1.14rem 1.62rem;
    }
  }
  &--background {
    background: #fff;
  }
  &--bigger {
    .react-datepicker {
      font-size: 1.3rem !important;
    }
    
    .react-datepicker__current-month {
      font-size: 1.5rem !important;
    }
    
    .react-datepicker__header {
      padding-top: 6px !important;
    }
    
    .react-datepicker__navigation {
    }
    
    .react-datepicker__day-name, .react-datepicker__day {
      margin: 0.5rem !important;
    }
  }
  .react-datepicker {
    &__year-dropdown {
      max-height: 18rem;
      overflow: auto;
    }
    &__year-option:last-of-type {
      display: none;
    }
  }
}
