.result-badge {
  padding: 2rem 2.4rem 2rem;
  border-radius: 0.8rem;
  font-size: 2.4rem;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;

  &--approved {
    background-color: #4d8400;
  }
  &--more {
    background-color: #f2be41;
    font-size: 1.4rem;
  }
  span {
    margin-left: 1rem;
  }
}
