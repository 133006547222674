@import '../../styles/lib';

.form-section {
  border-radius: 0.8rem;
  &__content {
    padding: 1.1rem;
    display: none;
    &--is-open {
      display: block;
    }
  }
  &__row {
    display: flex;
    margin-bottom: 1.9rem;
  }
  &__column {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &:not(:last-child) {
      margin-right: 2.2rem;
    }
    &--span2 {
      flex: 2;
    }
  }
  &__toggle {
    width: 100%;
    background: #f1ebda;
    border: rgba(0, 0, 0, 0.3) 0.075rem solid;
    padding: 0.8rem 1.7rem 1rem;
    border-radius: 0.8rem;
    text-align: left;
    color: black;
  }
  &--expanded {
    border: rgba(0, 0, 0, 0.3) 0.075rem solid;
    .form-section__toggle {
      background: transparent;
      border: none;
    }
  }

  @media (max-width: $breakpoint-mid-tablet) {
    &__row {
      flex-direction: column;
      margin-bottom: 0;
    }
    &__column {
      margin-bottom: 2rem;
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}
